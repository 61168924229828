import { Container, Row, Col} from 'react-bootstrap';

const Footer=()=> {

  return (

    <footer>
        <Container >
          <hr/>
            <Row>
              <Col xs={12} md={2} lg={2} className='text-center py-0' >
              <strong>
                Kurumsal
              </strong> 
              <p className='text-center m-0' > <a href="/aboutus">Hakkımızda</a></p>
              <p  className='text-center m-0'> <a href="/secretpolicy">Gizlilik Sözleşmesi</a></p>
              <p  className='text-center m-0'> <a href="/userpolicy">Kullanıcı Sözleşmesi</a></p>
              <p  className='text-center m-0'><a href="/returnpolicy">İade Şartları</a></p>

              </Col>
                <Col xs={12} md={2} lg={2} className='text-center py-0'>
                <img src='/images/Un2.jpg' width={225} height={126} />
               
                </Col>
                <Col xs={12} md={2} lg={2} className='text-center py-5'>
                <a href="https://www.linkedin.com/in/adp-end%C3%BCstriyel-otomasyon-115a5825a/" target="_blank" rel="noopener noreferrer"><img src='/images/Lnk.jpg' width={39} height={40} /></a> 
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a href="https://www.n11.com/magaza/adp" target="_blank" rel="noopener noreferrer"><img src='/images/n12.jpg' width={108} height={38} /></a>
                
                   
                </Col>

                <Col xs={12} md={2} lg={2} className='text-center py-0'>
                <img src='/images/3D.jpg' width={161} height={124} />

                </Col>
                <Col xs={12} md={3} lg={3} className='text-center py-0'>
                <strong>
                Bize Ulaşın
              </strong>
              <Row>
               &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; E-mail: satis@adpotomasyon.com.tr
              </Row>
              <Row>
                &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Tel: 0532 494 84 27
              </Row>
              <Row>
                &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  Adres: Mandıra Cad. Teker Sok. No:2/D Kadıköy-İstanbul
              </Row>
                </Col>
               

            </Row>
        </Container>
    </footer>
  )
}

export default Footer;